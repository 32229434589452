.footer-text {
  text-align: center;
  color: #868e96;
  font-weight: bold;
  font-family: "Google Sans Regular";
  font-size: 18px;
}

.footer-div {
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .footer-text {
    text-align: center;
    color: #868e96;
    font-weight: bold;
    font-family: "Google Sans Regular";
    font-size: 14px;
  }
}